import './header.scss';

import React, {useState} from 'react';
import {Storage, Translate} from 'react-jhipster';
import {Collapse, Nav, Navbar, NavbarToggler, Row} from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import {
  AssessmentAdminMenu,
  AssessmentMenu,
  AssessorDashboardMenu,
  Brand,
  BrandBlack,
  CandidateMenu,
  CategoryMenu,
  LandingMenu,
  TrainingList,
  TrainingMenu
} from './header-components';
import {AdminMenu, PaymentHistoryMenu} from '../menus';
import {IUser} from 'app/shared/model/user.model';
import {useHistory} from 'react-router-dom';
import {ProfileImage} from 'app/shared/components/profile-image';
import {
  ENABLE_HIRER_DASHBOARD,
  ENABLE_RESUME,
  ENABLE_TRAINING,
  FULL_PROFILE_MODE
} from 'app/config/constants';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isApplicant: boolean;
  isHirer: boolean;
  account: IUser;
  creditVal: number;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
}

export const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    props.onLocaleChange(langKey);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (

      <div id="app-header" style={{minHeight:110, width:280}}>
          {renderDevRibbon()}
          <LoadingBar className="loading-bar" />

          {/* Authenticated users */}
          {props.isAuthenticated && (
            <Navbar light expand="sm" className="bg-primary left">
              <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
              <Brand isApplicant={props.isApplicant} isHirer={props.isHirer} isAuthenticated={props.isAuthenticated}/>
              <Collapse isOpen={menuOpen} navbar style={{display:'flex',flexDirection:'column'}}>

                  <Nav className="header-tabs nav nav-item-left mb-4 pb-4" navbar style={{maxHeight:360}}>
                      <Row className="justify-content-center align-items-center w-100">
                          <ProfileImage account={props.account}/>
                          <div>
                              <div className="oblong-button-purple mt-5">
                                  <div className="d-flex align-items-center nav-link" style={{color:'#ffffff', cursor:'pointer'}} onClick={()=>history.push('/account/profile')}>
                                      My Profile
                                  </div>
                              </div>
                            {
                              ENABLE_RESUME &&
                              <Row className="justify-content-center mt-3">
                                <div className="d-flex align-items-center nav-link"  style={{cursor:'pointer'}}  onClick={()=>history.push('/account/resume')}>My Resume</div>
                              </Row>
                            }
                          </div>
                      </Row>
                  </Nav>

                <Nav className="header-tabs nav nav-item-left" navbar>
                    {props.isAuthenticated && props.isApplicant && (
                        <CandidateMenu/>
                    )}
                    {props.isAuthenticated && props.isHirer && ENABLE_HIRER_DASHBOARD && (
                      <AssessorDashboardMenu/>
                    )}
                     {/* {props.isAuthenticated && props.isApplicant && ( */}
                     {/*  <AssessmentList/> */}
                     {/* )} */}
                    {props.isAuthenticated && props.isApplicant && ENABLE_TRAINING && (
                      <TrainingList/>
                    )}
                    {props.isAuthenticated && props.isHirer && (
                        <AssessmentMenu/>
                    )}
                    {props.isAuthenticated && props.isHirer && ENABLE_TRAINING && (
                        <TrainingMenu/>
                    )}
                    {props.isAuthenticated && props.isAdmin && (
                        <CategoryMenu/>
                    )}
                    {props.isAuthenticated && props.isAdmin && (
                        <AssessmentAdminMenu/>
                    )}

                    {props.isAuthenticated && props.isAdmin && (
                        <div style={{paddingTop:10,paddingBottom:10}}>
                          <AdminMenu showSwagger={props.isSwaggerEnabled}
                                     showDatabase={!props.isInProduction}/>
                        </div>

                    )}

                    {props.isAuthenticated && props.isHirer && (
                        <PaymentHistoryMenu isAuthenticated={props.isAuthenticated}
                                            isHirer={props.isHirer} creditVal={props.creditVal}/>
                    )}
                    {/* <SettingsMenu isAuthenticated={props.isAuthenticated} isHirer={props.isHirer} */}
                    {/*               creditVal={props.creditVal}/> */}
                </Nav>
              </Collapse>
            </Navbar>
          )}

          {/* Non-Authenticated users */}
          {!props.isAuthenticated && (
              <Navbar light expand="sm" fixed="top" className="bg-primary1" style={{paddingTop:25,paddingBottom:25,backgroundColor:'#ffffff'}}>
                  <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
                  <BrandBlack isAuthenticated={props.isAuthenticated}/>
                  <Collapse isOpen={menuOpen} navbar style={{paddingRight:200}}>
                    <LandingMenu/>
                  </Collapse>
              </Navbar>

          )}

    </div>
  );
};



