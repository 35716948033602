import React from 'react';
import "./general-details.scss";
import '../assessment.scss';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InputRange from 'react-input-range';
import TextField from '@material-ui/core/TextField';
import {displayImage, isBlank} from 'app/shared/util/common-utils';
import {IAssessment} from 'app/shared/model/assessment.model';
import {WithOutContext as ReactTags} from 'react-tag-input-no-dnd';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getTags} from '../../tag/tag.reducer';
import {ITag} from 'app/shared/model/tag.model';

interface GeneralDetailsProps {

    model: IAssessment

    titleCallback:any;
    descriptionCallback:any;
    randomizeCallback:any;
    clearBlob:any;
    blobChangeCallback:any;
    percentCallback:any;
    allowViewScoreCallback: any;
    allowPublicCallback: any;
    tagsCallback: any;
    existingTags: Readonly<ITag[]>;

    nextStep:any;
}

interface GeneralDetailsState{
    errors:string[];
    tags: string[];
}

const KeyCodes = {
    comma: 188,
    enter: 13
};

export class GeneralDetails extends React.Component<GeneralDetailsProps,GeneralDetailsState> {

    constructor(props) {
        super(props);
        this.state = {
            errors:[],
            tags:[]
        };
    }

    componentDidMount() {

    }

    validate():void{
        this.setState({errors:[]}); // clear existing errors
        const currentErrors = [];

        if(isBlank(this.props.model.title)){
            currentErrors.push('Title required');
        }
        if(this.props.model.percent === 0){
            currentErrors.push('Passing score required');
        }
        this.setState({errors:currentErrors});
        if(currentErrors.length === 0){
            this.props.nextStep();
        }

    }

    updateTags(tag:string){
        if(this.state.tags.filter(t=>t===tag).length===0){
            this.setState({tags:[...this.state.tags,tag]},()=>{
                this.props.tagsCallback(this.state.tags);
            })
        }
    }


    render() {
        return (
            <div>
                <div className="page-title">
                    <h4>
                        <span><b><Translate
                            contentKey="evaluateyouApp.assessment.steps.step1">Title</Translate></b></span>
                    </h4>
                </div>
                {this.state.errors ? (<label style={{color: "red"}}>{
                    this.state.errors.map(error=><div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}
                <Row>
                    <Col md="6">
                        <Row className="form-row">
                            <TextField id="assessment-title"
                                       label="Title"
                                       variant="filled"
                                       fullWidth
                                       name="title"
                                       value={this.props.model.title}
                                       helperText="Be brief but concise to avoid mix ups."
                                       required
                                       onChange={this.props.titleCallback}
                            />
                        </Row>
                        <Row className="form-row">
                            <TextField id="assessment-description"
                                       label="Description"
                                       variant="filled"
                                       fullWidth
                                       multiline
                                       rows={4}
                                       name="description"
                                       value={this.props.model.description}
                                       helperText="Description of your assessment"
                                       onChange={this.props.descriptionCallback}
                            />
                        </Row>
                        <Row className="form-row">
                            <ReactTags
                                tags={this.state.tags}
                                suggestions={this.props.existingTags && this.props.existingTags.map(t=>{ return {id:t.id.toString(), text:t.name} })}
                                delimiters={[KeyCodes.comma, KeyCodes.enter]}
                                handleDelete={(i)=>this.setState({tags:this.state.tags.filter( (t,idx)=>idx !==i)})}
                                handleAddition={(tag)=>this.updateTags(tag)}
                                autocomplete
                                inline={true}
                            />
                        </Row>
                    </Col>
                    <Col md="6">
                        <Label id="percentLabel" for="assessment-percent">
                            <Translate
                                contentKey="evaluateyouApp.assessment.passPercentage">Percentage</Translate>
                        </Label><br/>
                        <InputRange
                            maxValue={100}
                            minValue={0}
                            value={this.props.model.percent}
                            onChange={this.props.percentCallback}/>
                        <div style={{marginTop: "50px"}}>
                            <Label id="logoLabel" for="logo">
                                <Translate
                                    contentKey="evaluateyouApp.assessment.logo">Logo</Translate>
                            </Label>
                            <br/>
                            {this.props.model.logo ? (
                                <div>
                                    <Row>
                                        <Col md="6">
                                            <span>
                                              <img style={{padding: "10px", width: "100px"}} src={displayImage(this.props.model.logo)}/>
                                               <Button color="danger" onClick={this.props.clearBlob}>
                                                <FontAwesomeIcon icon="times-circle"/>
                                              </Button>
                                            </span>
                                        </Col>
                                        <Col md="6">
                                            <AvField id="file_logo" name="file_logo" type="file"
                                                     placeholder="Upload a logo"
                                                     onChange={this.props.blobChangeCallback}/>
                                        </Col>
                                    </Row>
                                </div>
                            ) : <input id="file_logo" name="file_logo" type="file"
                                       placeholder="Upload a logo"
                                       onChange={this.props.blobChangeCallback}/>}

                        </div>
                    </Col>
                </Row>

              <Row className="ml-3 mt-3">
                <Col xs={12} className="pretty p-default p-curve p-thick">
                  <input defaultChecked={this.props.model.allowViewScore}
                         type="checkbox"
                         onChange={this.props.allowViewScoreCallback}/>
                  <div className="state p-primary-o">
                    <label><Translate contentKey="evaluateyouApp.assessment.allowViewScore">Allow
                      View Score</Translate></label>
                  </div>
                </Col>
                <Col xs={12} className="mt-2">
                  <p style={{marginLeft:22,fontSize: 12, fontWeight: 400, opacity: 0.5}}>Candidates will be able to view their answers, mistakes & scores</p>
                </Col>
              </Row>
              <Row className="ml-3">
                <Col xs={12} className="pretty p-default p-curve p-thick">
                  <input defaultChecked={this.props.model.randomize}
                         type="checkbox"
                         onChange={this.props.randomizeCallback}/>
                  <div className="state p-primary-o">
                    <label>Randomize Questions</label>
                  </div>
                </Col>
                <Col xs={12} className="mt-2">
                  <p style={{marginLeft:22,fontSize:12,fontWeight:400,opacity:0.5}}>Candidates will get the same set of questions but these questions will be ordered differently</p>
                </Col>
              </Row>
              <Row className="ml-3">
                <Col xs={12} className="pretty p-default p-curve p-thick">
                  <input defaultChecked={this.props.model.allowPublic}
                         type="checkbox"
                         onChange={this.props.allowPublicCallback}/>
                  <div className="state p-primary-o">
                    <label>Allow Public Access</label>
                  </div>
                </Col>
                <Col xs={12} className="mt-2">
                  <p style={{marginLeft:22,fontSize:12,fontWeight:400,opacity:0.5}}>Anyone with the assessment link will be able to sit for the assessment</p>
                </Col>
              </Row>

                <div className="marginTop marginBottom"
                     style={{display: "block", marginBottom: "20px"}}>
                    <Button onClick={()=>this.validate()} className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
                          <FontAwesomeIcon icon="arrow-right"/>
                    </Button>
                </div>
            </div>
        );
    }


}


const mapStateToProps = (storeState: IRootState) => ({
    tags: storeState.tag.entities,
});

const mapDispatchToProps = {
    getTags,
};


