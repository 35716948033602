import React from 'react';
import {Translate} from 'react-jhipster';

import {Nav, NavbarBrand, NavItem, NavLink} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
      <img src="content/images/ey_logo.png" />
  </div>
);

export const Brand = props => {
  let to = "/";
  if (props.isAuthenticated) {
    to = props.isHirer ? "/assessor-dashboard" : "/dashboard";
  }
  return (
  <NavbarBrand tag={Link} to={to} className="brand-logo">
    <span className="brand-title">
      e<BrandIcon />aluateyou
    </span>
  </NavbarBrand>)
};

export const BrandBlack = props => (
    <div className="brand-logo">
    <span className="brand-title-black">
      <Brand />
    </span>
    </div>
);

export const CandidateMenu = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/dashboard" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />&nbsp;&nbsp;&nbsp;
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const AssessorDashboardMenu = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/assessor-dashboard" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />&nbsp;&nbsp;&nbsp;
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const LandingMenu = props => (
    <Nav id="header-tabs" className="ml-auto" navbar>

        <NavItem>
            <NavLink tag={Link} to="/login" className="d-flex align-items-center">
                <div style={{paddingRight:25,paddingLeft:25,paddingTop:10,paddingBottom:10,backgroundColor:'#363987',borderRadius: 20,color:'#FFFFFF'}}>
                    Login
                </div>
            </NavLink>
        </NavItem>
    </Nav>


);

export const AssessmentMenu = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/assessment" className="d-flex align-items-center">
      <FontAwesomeIcon icon="list-alt" />&nbsp;&nbsp;&nbsp;
      <span>
        <Translate contentKey="global.menu.assessment">Assessment</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const TrainingMenu = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/training" className="d-flex align-items-center">
      <FontAwesomeIcon icon="list-alt" />&nbsp;&nbsp;&nbsp;
      <span>
        <Translate contentKey="global.menu.training">Training</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const TrainingList = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/training-list" className="d-flex align-items-center">
      <FontAwesomeIcon icon="list-alt" />&nbsp;&nbsp;&nbsp;
      <span>
        Trainings
      </span>
    </NavLink>
  </NavItem>
);

export const AssessmentList = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/assessment-list" className="d-flex align-items-center">
      <FontAwesomeIcon icon="list-alt" />&nbsp;&nbsp;&nbsp;
      <span>
        Assessments
      </span>
    </NavLink>
  </NavItem>
);

export const AssessmentAdminMenu = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/allAssessment" className="d-flex align-items-center">
      <FontAwesomeIcon icon="list-alt" />&nbsp;&nbsp;&nbsp;
      <span>
        <Translate contentKey="global.menu.assessment">Assessment</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const CategoryMenu = props => (
  <NavItem className="side-nav">
    <NavLink tag={Link} to="/audit" className="d-flex align-items-center">
      <FontAwesomeIcon icon="book-open" />&nbsp;&nbsp;&nbsp;
      <span>
        <Translate contentKey="global.menu.categories">Categories</Translate>
      </span>
    </NavLink>
  </NavItem>
);
