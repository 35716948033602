import '../assessment.scss';
import React from 'react';
import {Button, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";
import {FormControl, InputLabel, Select} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAudit} from 'app/shared/model/audit.model';
import {ErrorOutline} from '@material-ui/icons';
import {IAssessmentAudit} from 'app/shared/model/assessment-audit.model';

interface CustomTechnologySelectionProps {
  audits: IAudit[];
  isCustom: boolean;
  selectedAuditCounters: AuditCounter[];
  selectedAssessmentAudits: IAssessmentAudit[];

  previousStep: Function;
  nextStep: Function;

}

interface CustomTechnologySelectionState {
  errors: string[];

}

export class CustomTechnologySelection extends React.Component<CustomTechnologySelectionProps, CustomTechnologySelectionState> {

  maxQuestions: number;

  constructor(props) {
    super(props);
    this.maxQuestions = 30;
    this.state = {
      errors: []
    }
  }

  validate(): void {

    if (this.checkErrors()) {
      // populate selected audits
      this.props.nextStep();
    }
  }

  private checkErrors():boolean {
    this.setState({errors: []}); // clear existing errors
    const totalQuestions = this.getTotalQuestionCount();

    if (totalQuestions === 0) {
      this.setState({errors: [`Please select at least ${this.maxQuestions} questions`]});
      return false;
    }

    if (totalQuestions > this.maxQuestions) {
      this.setState({errors: ['Total selected questions have exceeded ' + this.maxQuestions]});
      return false;
    }
    return true;
  }

  getTotalQuestionCount(): number {

    let totalQuestions = 0;
    this.props.selectedAssessmentAudits.forEach((a) => {
      totalQuestions += a.numOfQuestions;
    });
    return totalQuestions;
  }

  updateCount(event: React.ChangeEvent<{ name?: string; value: unknown }>, audit: IAudit) {


    const questionCount = Number(event.target.value);

    const selectedAudit = this.props.selectedAssessmentAudits.find(a=>a.audit.id === audit.id);
    if(selectedAudit){
      selectedAudit.numOfQuestions = questionCount;
    }else{
      this.props.selectedAssessmentAudits.push({numOfQuestions: questionCount,audit: audit});
    }

    this.checkErrors();

  }

  getQuestionCount(audit: IAudit): number {
    const selected = this.props.selectedAssessmentAudits.find(selectedAssessmentAudit => selectedAssessmentAudit.audit.id === audit.id);
    if(!selected){
      return 0;
    }
    return selected.numOfQuestions;
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <div className="page-title">
          <h4>
            <span><b><Translate contentKey="evaluateyouApp.assessment.steps.step3">Title</Translate></b></span>
          </h4>

          <p className="label-secondary">These are knowledge or tech skills that are important for
            the candidate. Choose a maximum of <span
              style={{fontWeight: 'bold'}}>{this.maxQuestions}</span> questions.</p>
        </div>

        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error =>
            <div key={error} className="px-3 py-3 mb-3" style={{border: '1px solid red'}}>
              <ErrorOutline style={{color: 'red'}}/><span className="ml-2"
                                                          style={{fontSize: 16}}>{error}</span>
            </div>)}</label>) : (<span></span>)}

        <AvGroup>
          <Row style={{height: 'auto'}}>
            {this.props.audits ? this.props.audits.map((audit, index) => (

              <div key={index} className="technology-main">
                <FormControl variant="filled" className="tech-question-count"
                             error={this.getTotalQuestionCount() > this.maxQuestions ? true : false}>
                  <InputLabel htmlFor="filled-age-native-simple">Qty</InputLabel>
                  <Select
                    native
                    name="questionCount"
                    value={this.getQuestionCount(audit)}
                    onChange={(event) => this.updateCount(event, audit)}
                  >
                    <option aria-label="-" value=""/>
                    {[...Array(this.maxQuestions)].map((e, i) => <option key={i}
                                                                         value={i}>{i}</option>)}
                  </Select>
                </FormControl>
                <div className="tech-description">
                  <label><b>{audit.name}</b></label>
                  <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    <span><i>{audit.description}</i></span>
                  </div>
                </div>
              </div>

            )) : <div>No secondary question categories found</div>}
          </Row>
          <AvFeedback>
            <Translate contentKey="entity.validation.required">This field is
              required.</Translate>
          </AvFeedback>
        </AvGroup>
        <div className="marginTop marginBottom">
          <Button onClick={() => this.props.previousStep()} color="info" className="btn-primary">
            <FontAwesomeIcon icon="arrow-left"/>
            &nbsp;
            <span className="btn-primary">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
          </Button>
          <Button onClick={() => this.validate()} color="info"
                  className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>
      </div>
    )
  }


}

export class AuditCounter {
  audit: IAudit;
  count: number
}
