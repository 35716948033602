import '../assessment.scss';
import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAssessment} from 'app/shared/model/assessment.model';
import {displayImage} from 'app/shared/util/common-utils';
import {IAudit} from 'app/shared/model/audit.model';
import {IAssessmentAudit} from 'app/shared/model/assessment-audit.model';
import {Chip} from '@material-ui/core';

interface ConfirmationProps{
    model: IAssessment,
    emails: string[];
    selectedAudits: IAssessmentAudit[],

    previousStep:Function;
    submit: Function;
}

export class Confirmation extends React.Component<ConfirmationProps> {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>
                <div className="page-title">
                    <h4>
                        <span><Translate
                            contentKey="evaluateyouApp.assessment.steps.step5Title">Title</Translate></span>
                    </h4>
                </div>
                <Row>
                    <Col md="4">
                        <div><b><Translate
                            contentKey="evaluateyouApp.assessment.title">Title</Translate></b></div>
                        <div className="assessment-detail-title">
                            <span>{this.props.model.title}</span>
                        </div>
                    </Col>
                    <Col md="4">
                        <div><b><Translate
                            contentKey="evaluateyouApp.assessment.description">Title</Translate></b>
                        </div>
                        <div className="assessment-detail-title">
                            <span>{this.props.model.description}</span>
                        </div>
                    </Col>
                    <Col md="4">
                        <div><b><Translate
                            contentKey="evaluateyouApp.assessment.percentage">Title</Translate></b>
                        </div>
                        <div className="assessment-detail-title">
                            <span>{this.props.model.percent}%</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">

                            <div><b>Technologies</b></div>
                            <div className="assessment-detail-title">
                                {this.props.selectedAudits
                                    ? this.props.selectedAudits.map(audit => (
                                        <div className="my-2" key={audit.id}>{audit.audit.name} <Chip className="ml-2" label={`${audit.numOfQuestions} questions`} /></div>
                                    ))
                                    : null}
                            </div>
                    </Col>
                    <Col md="4">
                        <div className="dl-horizontal jh-entity-details">

                            <div><b><Translate
                                contentKey="evaluateyouApp.assessment.candidates">Title</Translate></b>
                            </div>

                            <div className="assessment-detail-title">
                                {this.props.emails
                                    ? this.props.emails.map(email => (
                                        <span key={email}>{email}<br/></span>
                                    ))
                                    : null}
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div><b><Translate
                            contentKey="evaluateyouApp.assessment.allowViewScore">Title</Translate></b>
                        </div>
                        <div className="assessment-detail-title">
                            <span>{this.props.model.allowViewScore ? "true" : "false"}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <div><b><Translate
                            contentKey="evaluateyouApp.assessment.logo">Title</Translate></b>
                        </div>
                        <div>
                            {this.props.model.logo ? (
                                <div>
                                    <Row>
                                        <Col md="6">
                                            <span>
                                              <img style={{padding: "10px", width: "100px"}}
                                                   src={displayImage(this.props.model.logo)}/>
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div><b>Is Public?</b>
                        </div>
                        <div className="assessment-detail-title">
                            <span>{this.props.model.allowPublic ? "true" : "false"}</span>
                        </div>
                    </Col>
                </Row>


                <div className="marginTop marginBottom mt-3">
                    <Button onClick={() => this.props.previousStep()} color="info"
                            className="btn-primary">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
                    </Button>&nbsp;
                    <Button className="pull-right" color="primary" id="save-entity" type="submit"
                            onClick={() => this.props.submit()}>
                        <FontAwesomeIcon icon="save"/>
                        &nbsp;
                        <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                </div>
            </div>
        )
    }


}
