import './assessment.scss';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {setFileData, Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {CONSTANTS, MAX_SCORE} from "app/shared/util/global.constants";

import {createEntity, getEntity, reset, setBlob, updateEntity} from './assessment.reducer';
import {getCredit} from 'app/entities/credit/credit.reducer';
import {IAssessment} from 'app/shared/model/assessment.model';
import {TIME_FORMAT, toTimeZoneWithFormat} from 'app/shared/util/date-utils';
import AuditService from 'app/shared/service/audit.service';
import {IAssessmentAudit} from "app/shared/model/assessment-audit.model";
import {IAssessmentEmail} from "app/shared/model/assessment-email.model";
import AssessmentService from "app/shared/service/assessment.service";
import CreditService from "app/shared/service/credit.service";
import {IAudit} from 'app/shared/model/audit.model';
import {GeneralDetails} from 'app/entities/assessment/steps/general-details';
import {DifficultySelection} from 'app/entities/assessment/steps/difficulty-selection';
import {TechnologySelection} from 'app/entities/assessment/steps/technology-selection';
import {ExistingAssessment} from 'app/entities/assessment/steps/existing-assessment';
import {CandidateDetails} from 'app/entities/assessment/steps/candidate-details';
import {Confirmation} from 'app/entities/assessment/steps/confirmation';
import {APP_LOCAL_DATETIME_FORMAT_Z} from 'app/config/constants';
import moment from 'moment';
import {ProgressBar, ProgressBarOption} from 'app/entities/assessment/steps/progress-bar';
import {
  AuditCounter,
  CustomTechnologySelection
} from 'app/entities/assessment/steps/custom-technology-selection';
import {getEntities as getTags} from 'app/entities/tag/tag.reducer';


export interface IAssessmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAssessmentUpdateState {
  isNew: boolean;
  allAudits: IAudit[];
  audits2Group: any;
  selectedAudits: IAudit[]; // only shows selected audits. Used when users do not select questions via the `Guide Me` option
  selectedDifficulty: string;
  selectedAssessmentAudits: IAssessmentAudit[]; // shows the audit and total questions to use for the exam
  selectedTechnologyQuestions: AuditCounter[]
  emails: string[];
  currentStep: number;
  completedSteps: number[];
  errors: any;
  showSuccessForm: boolean;
  logo: any;
  percentage: any;
  model: IAssessment;
  currentStepOption: ProgressBarOption;
}

export class AssessmentUpdate extends React.Component<IAssessmentUpdateProps, IAssessmentUpdateState> {
  auditService: AuditService;
  creditService: CreditService;
  assessmentService: AssessmentService;
  assessmentAudits: IAssessmentAudit[];
  assessmentEmails: IAssessmentEmail[];
  model: IAssessment;
  credit: any;
  steps:ProgressBarOption[];
  isCustom:boolean;
  isExisting:boolean;

  constructor(props) {
    super(props);

    this.isCustom = props.location.pathname.includes('new-custom-questions')?true:false;
    this.isExisting = props.location.pathname.includes('new-existing-questions')?true:false;
    if(this.isCustom){
      this.steps = [
        {
          step: 1,
          stepTitle: 'Details',
          renderStep: ()=>this.renderGeneralDetails()
        },
        {
          step: 2,
          stepTitle: 'Technology',
          renderStep: ()=>this.renderCustomQuestionSelection()
        },
        {
          step: 3,
          stepTitle: 'Candidates',
          renderStep: ()=>this.renderCandidates()
        },
        {
          step: 4,
          stepTitle: 'Confirmation',
          renderStep: ()=>this.renderConfirmation()
        }
      ];
    }else if (this.isExisting){
      this.steps = [
        {
          step: 1,
          stepTitle: 'Assessments',
          renderStep: ()=>this.renderExistingAssessment()
        },
        {
          step: 2,
          stepTitle: 'Candidates',
          renderStep: ()=>this.renderCandidates()
        },
        {
          step: 3,
          stepTitle: 'Confirmation',
          renderStep: ()=>this.renderConfirmation()
        }
      ]
    }else{
      this.steps = [
        {
          step: 1,
          stepTitle: 'Details',
          renderStep: ()=>this.renderGeneralDetails()
        },
        {
          step: 2,
          stepTitle: 'Difficulty',
          renderStep: ()=>this.renderDifficulty()
        },
        {
          step: 3,
          stepTitle: 'Categories',
          renderStep: ()=>this.renderQuestionCategories()
        },
        {
          step: 4,
          stepTitle: 'Candidates',
          renderStep: ()=>this.renderCandidates()
        },
        {
          step: 5,
          stepTitle: 'Confirmation',
          renderStep: ()=>this.renderConfirmation()
        },
      ];
    }

    this.assessmentAudits = [];
    this.assessmentEmails = [];
    this.credit = {};
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      allAudits:[],
      selectedDifficulty: '',
      audits2Group: [],
      selectedAudits: [],
      selectedAssessmentAudits:[],
      selectedTechnologyQuestions:[],
      completedSteps:[],
      emails: [],
      currentStep: 1,
      errors:{errorSelectNormal: false, errorSelectCore: false, errorCredit: false, visitedTitle: false, visitedPercentage: false},
      showSuccessForm: false,
      logo: "",
      percentage: 0,
      model: {
        id: null,
        title: '',
        description: '',
        percent: 0,
        allowViewScore: false,
        randomize: false,
        showAnswers: false,
        allowPublic: false,
        assessmentEmails: [],
        assessmentAudits: []
      },
      currentStepOption: this.steps[0] // the first one

    };
    this.auditService = new AuditService();
    this.assessmentService = new AssessmentService();
    this.creditService = new CreditService();
  }

  componentWillUpdate(nextProps) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {

    this.props.getTags(0,99999,'asc');

    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.auditService.getDTOEntitiesByQuestionsEnabled().then(data => {
      this.setState({allAudits:data});
    });


    this.creditService.getCredit().then( response =>{
      if(response.status === CONSTANTS.SUCCESS){
        this.credit = response.form;
      }
    });
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => {
      this.props.setBlob(name, data, contentType);
      const model = this.state.model;
      model.logo = data;
      this.setState({model:model});
      }, isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
    const model = this.state.model;
    model.logo = null;
    this.setState({model:model});
  };

  getNextStep():ProgressBarOption{
    const nextStep = this.state.currentStepOption.step + 1;
    return this.steps[nextStep -1]; // index are zero based
  }

  getPreviousStep():ProgressBarOption{
    const previous = this.state.currentStepOption.step - 1;
    return this.steps[previous -1]; // index are zero based
  }

  showPrevStep = () =>{
    this.setState({currentStepOption: this.getPreviousStep()});
  }

  showNextStep = () =>{
      this.stepComplete(this.state.currentStepOption.step);
      this.setState({currentStepOption: this.getNextStep()});
  }



  saveEntity = () => {

      const model = this.state.model;
      model.expiryDate =  moment(new Date(), APP_LOCAL_DATETIME_FORMAT_Z);
      model.difficulty = this.state.selectedDifficulty;

      model["assessmentAudits"] = this.state.selectedAssessmentAudits;
      const assessmentEmails = [];
      for(let i = 0; i < this.state.emails.length; i++){
        const obj = {email: this.state.emails[i]};
        assessmentEmails.push(obj);
      }
      model["assessmentEmails"] = assessmentEmails;

      const entity = {
        ...model
      }

    if (this.state.isNew) {
        this.assessmentService.createEntity(entity).then(data => {
          this.props.getCredit();
          this.model = data;
          this.setState({showSuccessForm: true});
        });
    }
  };

  handleClose = () => {
    this.props.history.push('/assessment');
  }

  getExpiredDate = () => {
    const date = new Date();
    const WEEK = 7;
    date.setDate(date.getDate() + WEEK);
    return date;
  }

  onDifficultyChange = (event, difficulty:string) =>{
    this.setState({selectedDifficulty:difficulty})
  }

  onSelectAuditChange = (event, audit: IAudit) => {

    const {selectedAudits} = this.state;
    const index = selectedAudits.indexOf(audit);

    if(index === -1){ // not found
      if(event.target.checked){ // audit was selected
        selectedAudits.push(audit);
      }
    }else{ // found
      if(!event.target.checked) { // audit was not selected
        selectedAudits.splice(index, 1);
      }
    }
    this.populateQuestionsPerAudit();
  }

  setModelValue = (field, event) => {
    let value;
    if(field === "allowViewScore" || field === "randomize" || field === "allowPublic"){
      value = event.target.checked;
    }else{
      value = event.target.value;
    }

    const existingModel = this.state.model;
    existingModel[field] = value;

    this.setState({model:existingModel});
  }

  setExistingModelValue = (field, event) => {
    const value = event;
    const existingModel = this.state.model;
    existingModel[field] = value;

    this.setState({model:existingModel});
  }

  setModelPercentage = (value) => {
    const existingModel = this.state.model;
    existingModel.percent = value;
    this.setState({model: existingModel});
  }

  changeEmails = chips =>{
    this.setState({emails: chips });
  }


  populateQuestionsPerAudit(){
    const {selectedAudits} = this.state;
    const assessmentAudits = [];
    const coreAudit = [], normalAudit = [];
    let totalCoreQuestion = 0;
    for(let i = 0; i < selectedAudits.length; i++){
      const selectedAudit = selectedAudits[i];
      const assessmentAudit: IAssessmentAudit = {numOfQuestions: 0, audit: selectedAudit};
      if(selectedAudit.core){
        totalCoreQuestion+= selectedAudit.totalEnabledQuestions;
        coreAudit.push(selectedAudit);
      }else{
        normalAudit.push(selectedAudit);
      }
      assessmentAudits.push(assessmentAudit);
    }

    let step = 1, prev = 0, prevExpectNormalQuestion = 0;
    let expectCoreQuestion = normalAudit.length !== 0 ? (MAX_SCORE - step * normalAudit.length) : (MAX_SCORE > totalCoreQuestion ? totalCoreQuestion: MAX_SCORE);
    while (totalCoreQuestion < expectCoreQuestion && normalAudit.length > 0){
      step += 1;
      expectCoreQuestion = (MAX_SCORE - step * normalAudit.length) > 0 ? (MAX_SCORE - step * normalAudit.length) : totalCoreQuestion;
    }
    let expectNormalQuestion = MAX_SCORE - expectCoreQuestion;

    // repopulate when the questions of score > 20
    if(expectCoreQuestion > 20 && expectNormalQuestion !== 0){
      step = 2;
      expectCoreQuestion = normalAudit.length !== 0 ? ((MAX_SCORE - step * normalAudit.length) > 0 ? (MAX_SCORE - step * normalAudit.length) : (MAX_SCORE - normalAudit.length) ) : (MAX_SCORE > totalCoreQuestion ? totalCoreQuestion: MAX_SCORE);
      while (totalCoreQuestion < expectCoreQuestion && normalAudit.length > 0){
        step += 1;
        expectCoreQuestion = (MAX_SCORE - step * normalAudit.length) > 0 ? (MAX_SCORE - step * normalAudit.length) : totalCoreQuestion;
      }
      expectNormalQuestion = MAX_SCORE - expectCoreQuestion;
    }

    while (expectCoreQuestion > 0 && prev !== expectCoreQuestion){
      prev = expectCoreQuestion;
      for(let i = 0; i < coreAudit.length && expectCoreQuestion > 0; i++){
        const audit = coreAudit[i];
        const indexOf = selectedAudits.indexOf(audit);
        const assessmentAudit = assessmentAudits[indexOf];
        if(assessmentAudit.numOfQuestions < audit.totalEnabledQuestions){
          assessmentAudit.numOfQuestions += 1;
          expectCoreQuestion -= 1;
        }
      }
    }

    while (expectNormalQuestion > 0 && prevExpectNormalQuestion !== expectNormalQuestion){
      prevExpectNormalQuestion = expectNormalQuestion;
      for(let i = 0; i < normalAudit.length && expectNormalQuestion > 0; i++){
        const audit = normalAudit[i];
        const indexOf = selectedAudits.indexOf(audit);
        const assessmentAudit = assessmentAudits[indexOf];
        if(assessmentAudit.numOfQuestions < audit.totalEnabledQuestions){
          assessmentAudit.numOfQuestions += 1;
          expectNormalQuestion -= 1;
        }
      }

      this.assessmentAudits = assessmentAudits;
      this.setState({selectedAssessmentAudits: assessmentAudits});
    }
  }

  hideDialog =() =>{
    this.setState({showSuccessForm: false});
    this.handleClose();
  }

  showTime = (time) => {
    const toString = toTimeZoneWithFormat(time, this.props.account.timeZone, TIME_FORMAT.DDMMMYYYY);
    return toString;
  }



  renderSuccess(){
    const {showSuccessForm, emails} = this.state;
    return (<Modal isOpen={showSuccessForm} toggle={() => this.hideDialog()} backdrop={true}>
      <ModalHeader toggle={() => this.hideDialog()}>
        <Translate contentKey="evaluateyouApp.assessment.messages.assessment.added"></Translate>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FontAwesomeIcon icon="hand-point-right"/>{' '}
            <Translate contentKey="evaluateyouApp.assessment.messages.assessment.info" interpolate={{date: this.showTime(this.state.model.expiryDate)}}></Translate>
          </Col>
        </Row>
        <Row>
          <Col>
            <FontAwesomeIcon icon="hand-point-right"/>{' '}
            <Translate contentKey="evaluateyouApp.assessment.messages.assessment.info2"></Translate>
          </Col>
        </Row>
        <Row>
          <Col>
            {emails
              ? emails.map(email => (
                <span  key={email}>{email}<br/></span>
              ))
              : null}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => this.hideDialog()}> <Translate contentKey="entity.action.ok"></Translate></Button>{' '}
      </ModalFooter>
    </Modal>);
  }

  renderGeneralDetails(){
    return (<GeneralDetails
                            model={this.state.model}
                            existingTags={this.props.tags}
                            titleCallback={(event) => this.setModelValue("title", event)}
                            descriptionCallback={(event) => this.setModelValue("description", event)}
                            clearBlob={this.clearBlob('logo')}
                            blobChangeCallback={this.onBlobChange(false, 'logo')}
                            percentCallback={value => this.setModelPercentage(value)}
                            allowViewScoreCallback={(event) => this.setModelValue("allowViewScore", event)}
                            tagsCallback={(tags) => this.populateTags(tags)}
                            nextStep={() => this.showNextStep()}
                            randomizeCallback={(event) => this.setModelValue("randomize", event)}
                            allowPublicCallback={(event) => this.setModelValue("allowPublic", event)}/>);
  }

  renderDifficulty(){
    return (<DifficultySelection
                                 nextStep={() => this.showNextStep()}
                                 onDifficultyChange={(event,difficulty) => this.onDifficultyChange(event, difficulty)}
                                 selectedDifficulty={this.state.selectedDifficulty}
                                 previousStep={() => this.showPrevStep()}

            />);
  }

  renderQuestionCategories(){
    return (<TechnologySelection
              previousStep={()=>this.showPrevStep()}
              onAuditChange={(event,audit) => this.onSelectAuditChange(event, audit)}
              nextStep={()=>this.showNextStep()}
              difficulty={this.state.selectedDifficulty}
              audits={this.state.allAudits}
              selectedAudits={this.state.selectedAudits}
              isCustom={this.isCustom}
            />);
  }

  renderExistingAssessment(){
    return (<ExistingAssessment
        model={this.state.model}
        account={this.props.account}
        titleCallback={(event) => this.setExistingModelValue("title", event)}
        descriptionCallback={(event) => this.setExistingModelValue("description", event)}
        clearBlob={this.clearBlob('logo')}
        blobChangeCallback={this.onBlobChange(false, 'logo')}
        percentCallback={value => this.setModelPercentage(value)}
        allowViewScoreCallback={(event) => this.setExistingModelValue("allowViewScore", event)}
        nextStep={() => this.showNextStep()}/>);
  }

  renderCustomQuestionSelection(){

    return (<CustomTechnologySelection
        previousStep={()=>this.showPrevStep()}
        nextStep={()=>this.showNextStep()}
        audits={this.state.allAudits}
        isCustom={this.isCustom}
        selectedAssessmentAudits={this.state.selectedAssessmentAudits}
        selectedAuditCounters={this.state.selectedTechnologyQuestions}/>);
  }

  renderCandidates(){
    return (<CandidateDetails changeEmail={(chips)=>this.changeEmails(chips)}
                              previousStep={()=>this.showPrevStep()}
                              nextStep={()=>this.showNextStep()}
                              emails={this.state.emails}
                              credit={this.credit}
                              allowPublic={this.state.model.allowPublic}
            />);
  }

  renderConfirmation(){
    return (<Confirmation model={this.state.model}
                          emails={this.state.emails}
                          selectedAudits={this.state.selectedAssessmentAudits}
                          previousStep={()=>this.showPrevStep()}
                          submit={()=>this.saveEntity()}/>);
  }

  renderCurrentStep(){
    return this.state.currentStepOption.renderStep();
  }

  render() {
    const { assessmentEntity, loading } = this.props;
    const { isNew } = this.state;

    return (
      <Card className="main-card" style={{height:'100%', paddingBottom: 30}}>
        {this.renderSuccess()}

        <Row>
          <ProgressBar currentStep={this.state.currentStepOption} isStepCompleted={(step)=>this.isStepCompleted(step)} stepOptions={this.steps}/>
        </Row>

        <Row className="justify-content-center">
          <Col md="10">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : assessmentEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="assessment-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="assessment-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                {
                  this.renderCurrentStep()
                }
              </AvForm>
            )}
          </Col>
        </Row>
      </Card>
    );
  }


  stepComplete(step: number):void {
    const completedSteps = this.state.completedSteps;
    completedSteps.push(step);
    this.setState({completedSteps: completedSteps});
  }

  isStepCompleted(step:number):boolean{
    return this.state.completedSteps.includes(step);
  }

  private populateTags(tags) {
    // array to comma separated string
    const tagsString = tags.map(tag => tag.text).join(',');
    this.setState({model:{...this.state.model,tags:tagsString}});
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  assessmentEntity: storeState.assessment.entity,
  loading: storeState.assessment.loading,
  updating: storeState.assessment.updating,
  updateSuccess: storeState.assessment.updateSuccess,
  account: storeState.authentication.account,
  tags: storeState.tag.entities,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset,
  getCredit,
  getTags,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentUpdate);
